import * as React from "react"
import Seo from "@components/seo"
import Layout from "@layout"
import Banner from "@components/banners/Ai";
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import PublicationAIArea from "@containers/publicationAI-area"

const PublicationAIPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <Seo title="All Publications" />
    <Header />
    {<Banner
			pageContext={pageContext}
			location={location}
			title="Publications on Artificial Intelligence"
		/>}
    <main className="site-wrapper-reveal">
      <PublicationAIArea />
    </main>
    <Footer />
  </Layout>
)

export default PublicationAIPage
